import React, { ReactElement, useContext } from 'react';

import {
  Accordion as AccordionBootstrap,
  Card,
  AccordionContext,
  useAccordionToggle
} from 'react-bootstrap';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Graphql } from '@models/graphql';

import './styles.scss';

type AccordionProps = {
  content: (_option: Graphql) => ReactElement;
  title: Function;
  options: any;
};

type AccordionContent = {
  children: unknown;
  eventKey: string;
  callback: Function | null;
};

function Accordion({
  title, content, options
}: AccordionProps): React.ReactElement {
  return (
    <AccordionBootstrap>
      {options.map((value: Graphql, index: number) => ({
        ...value,
        key: `${index}`,
      })).map((option: Graphql) => (
        <div key={option.key}>
          <ContextAwareToggle eventKey={`${option.key}`} callback={null}>
            <span className="m-0 text-dark-indigo lead">{title(option)}</span>
          </ContextAwareToggle>
          <AccordionBootstrap.Collapse eventKey={`${option.key}`}>
            <div className="card-body">
              {content(option)}
            </div>
          </AccordionBootstrap.Collapse>
        </div>
      ))}
    </AccordionBootstrap>
  );
}

function ContextAwareToggle({
  children,
  eventKey,
  callback
}: AccordionContent) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <AccordionBootstrap.Toggle
      as={Card.Header}
      variant="link"
      eventKey={children as string}
      className="bg-white border-0 cursor-pointer"
      onClick={decoratedOnClick}
    >
      <div className="d-flex align-items-center justify-content-between">
        <span className="m-0 text-dark-indigo lead">{children as string}</span>
        <span className={!isCurrentEventKey ? 'text-orange' : ''}>
          <FontAwesomeIcon icon={faPlusSquare as IconProp} size="lg" />
        </span>
      </div>
    </AccordionBootstrap.Toggle>
  );
}

export default Accordion;
